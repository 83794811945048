import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="UIカラー - カラー" mdxType="SEO" />
    <PageTitle title="UIカラー" enTitle="UI Color" mdxType="PageTitle" />
    <h2 {...{
      "id": "概要",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%A6%82%E8%A6%81",
        "aria-label": "概要 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`概要`}</h2>
    <p>{`UIカラーは、Amebaが提供するデジタルコンテンツの中で使用される、RGB色空間に最適化されたカラーパレットです。`}</p>
    <p>{`UIカラーを構成するデザイントークンとして大きくPrimitive ColorsとTheme Colorsの2種類が存在します。Primitive Colorsは、ブランドカラーに基づく主要な色相を11段階に展開しています。Theme Colorsは、Primitive Colorsで定義したカラーをUIで使えるように組み合わせを定義したデザイントークンです。`}</p>
    <p>{`デザイナー、開発者は原則Primitive Colorsをそのまま利用することはなく、Theme Colorsとして利用するようにしてください。`}</p>
    <p>{`背景色と前景色（テキストやアイコン等）の組み合わせは`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
      }}>{`WCAG2.1の達成基準 1.4.3`}</a>{`に基づき、十分なコントラスト比を担保するように設計されています。`}</p>
    <p>{`より詳しいカラーパレットの設計プロセスについて知りたい場合は`}<a parentName="p" {...{
        "href": "https://developers.cyberagent.co.jp/blog/archives/26754/"
      }}>{`CyberAgent Developers Blogの記事`}</a>{`を参照してください。`}</p>
    <h2 {...{
      "id": "種類",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%A8%AE%E9%A1%9E",
        "aria-label": "種類 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`種類`}</h2>
    <p>{`UIのレイヤー構造に基づき分類し、背景色と前景色の組み合わせを定義することで、十分なコントラスト比を保つように設計しています。`}</p>
    <p>{`カラーの種類は、レイヤーの階層の概念に紐付けて考えます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7f7e51008af380176c1edd12c33d451e/07a9c/uicolor-layers.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7f7e51008af380176c1edd12c33d451e/2aaaf/uicolor-layers.webp 160w", "/static/7f7e51008af380176c1edd12c33d451e/85e47/uicolor-layers.webp 320w", "/static/7f7e51008af380176c1edd12c33d451e/75198/uicolor-layers.webp 640w", "/static/7f7e51008af380176c1edd12c33d451e/691bc/uicolor-layers.webp 960w", "/static/7f7e51008af380176c1edd12c33d451e/223e5/uicolor-layers.webp 1280w", "/static/7f7e51008af380176c1edd12c33d451e/f093e/uicolor-layers.webp 1440w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7f7e51008af380176c1edd12c33d451e/69538/uicolor-layers.png 160w", "/static/7f7e51008af380176c1edd12c33d451e/72799/uicolor-layers.png 320w", "/static/7f7e51008af380176c1edd12c33d451e/6af66/uicolor-layers.png 640w", "/static/7f7e51008af380176c1edd12c33d451e/d9199/uicolor-layers.png 960w", "/static/7f7e51008af380176c1edd12c33d451e/21b4d/uicolor-layers.png 1280w", "/static/7f7e51008af380176c1edd12c33d451e/07a9c/uicolor-layers.png 1440w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7f7e51008af380176c1edd12c33d451e/6af66/uicolor-layers.png",
              "alt": "下層からBackground Color, Surface Color, Text Colorとレイヤーが重なるイメージ",
              "title": "下層からBackground Color, Surface Color, Text Colorとレイヤーが重なるイメージ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Theme Colorsは以下のように分類されます。また各分類ごとにLightとDarkのカラーパレットが用意されています。`}</p>
    <h3 {...{
      "id": "background-color",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#background-color",
        "aria-label": "background color permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Background Color`}</h3>
    <p>{`最下層に位置するカラーです。デザインツールでいえばアートボードの色であり、いずれのUIにも必ずあるものとします。`}</p>
    <div className="Stack">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/cf34742003c8c838865a2fc2ff274200/aac3c/uicolor-light-background.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "40%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/cf34742003c8c838865a2fc2ff274200/2aaaf/uicolor-light-background.webp 160w", "/static/cf34742003c8c838865a2fc2ff274200/85e47/uicolor-light-background.webp 320w", "/static/cf34742003c8c838865a2fc2ff274200/75198/uicolor-light-background.webp 640w", "/static/cf34742003c8c838865a2fc2ff274200/691bc/uicolor-light-background.webp 960w", "/static/cf34742003c8c838865a2fc2ff274200/223e5/uicolor-light-background.webp 1280w", "/static/cf34742003c8c838865a2fc2ff274200/84d70/uicolor-light-background.webp 2897w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/cf34742003c8c838865a2fc2ff274200/69538/uicolor-light-background.png 160w", "/static/cf34742003c8c838865a2fc2ff274200/72799/uicolor-light-background.png 320w", "/static/cf34742003c8c838865a2fc2ff274200/6af66/uicolor-light-background.png 640w", "/static/cf34742003c8c838865a2fc2ff274200/d9199/uicolor-light-background.png 960w", "/static/cf34742003c8c838865a2fc2ff274200/21b4d/uicolor-light-background.png 1280w", "/static/cf34742003c8c838865a2fc2ff274200/aac3c/uicolor-light-background.png 2897w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/cf34742003c8c838865a2fc2ff274200/6af66/uicolor-light-background.png",
                "alt": "Background ColorのLightテーマのカラーパレット",
                "title": "Background ColorのLightテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2556234da3294d35d0058e8f1b986230/ed358/uicolor-dark-background.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "40%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/2556234da3294d35d0058e8f1b986230/2aaaf/uicolor-dark-background.webp 160w", "/static/2556234da3294d35d0058e8f1b986230/85e47/uicolor-dark-background.webp 320w", "/static/2556234da3294d35d0058e8f1b986230/75198/uicolor-dark-background.webp 640w", "/static/2556234da3294d35d0058e8f1b986230/691bc/uicolor-dark-background.webp 960w", "/static/2556234da3294d35d0058e8f1b986230/223e5/uicolor-dark-background.webp 1280w", "/static/2556234da3294d35d0058e8f1b986230/b1afa/uicolor-dark-background.webp 2904w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/2556234da3294d35d0058e8f1b986230/69538/uicolor-dark-background.png 160w", "/static/2556234da3294d35d0058e8f1b986230/72799/uicolor-dark-background.png 320w", "/static/2556234da3294d35d0058e8f1b986230/6af66/uicolor-dark-background.png 640w", "/static/2556234da3294d35d0058e8f1b986230/d9199/uicolor-dark-background.png 960w", "/static/2556234da3294d35d0058e8f1b986230/21b4d/uicolor-dark-background.png 1280w", "/static/2556234da3294d35d0058e8f1b986230/ed358/uicolor-dark-background.png 2904w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/2556234da3294d35d0058e8f1b986230/6af66/uicolor-dark-background.png",
                "alt": "Background ColorのDarkテーマのカラーパレット",
                "title": "Background ColorのDarkテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    </div>
    <h3 {...{
      "id": "surface-color--text-color",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#surface-color--text-color",
        "aria-label": "surface color  text color permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Surface Color & Text Color`}</h3>
    <p>{`Surface＝表層であり、Backgroundの上に乗るカラーです。
アイコンなど、オブジェクト単体の色などにも適用します。
Button等、Surfaceレイヤーが二枚以上重なる場合もあります。`}</p>
    <p>{`Text Colorは全般なテキスト表現に使用するカラーです。
WCAG2.1に準拠し、原則として背景色に対してコントラスト比`}<inlineCode parentName="p">{`4.5:1`}</inlineCode>{`を保持する必要があります。
そのため、背景色に応じて選択可能な色が限定しているので注意してください。`}</p>
    <p>{`例外: Button等の非活性のUIの場合は `}<inlineCode parentName="p">{`4.5:1`}</inlineCode>{`未満の比率でも問題はありません。`}</p>
    {
      /* 実際の使用にあたってはBackground / Surface　の項目を参照してください。 */
    }
    <p>{`グレーに関しては、透過にすることによって多少の背景色の差異でもコントラスト比が保たれるように調整しています。
詳しい設計プロセスについては`}<a parentName="p" {...{
        "href": "https://developers.cyberagent.co.jp/blog/archives/26754/"
      }}>{`カラーパレットに関するブログ記事`}</a>{`を参照してください。`}</p>
    <div className="Stack">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/4d57e5c80481862497f0642cfa8755dd/aac3c/uicolor-light-surface.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "158.125%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4d57e5c80481862497f0642cfa8755dd/2aaaf/uicolor-light-surface.webp 160w", "/static/4d57e5c80481862497f0642cfa8755dd/85e47/uicolor-light-surface.webp 320w", "/static/4d57e5c80481862497f0642cfa8755dd/75198/uicolor-light-surface.webp 640w", "/static/4d57e5c80481862497f0642cfa8755dd/691bc/uicolor-light-surface.webp 960w", "/static/4d57e5c80481862497f0642cfa8755dd/223e5/uicolor-light-surface.webp 1280w", "/static/4d57e5c80481862497f0642cfa8755dd/84d70/uicolor-light-surface.webp 2897w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4d57e5c80481862497f0642cfa8755dd/69538/uicolor-light-surface.png 160w", "/static/4d57e5c80481862497f0642cfa8755dd/72799/uicolor-light-surface.png 320w", "/static/4d57e5c80481862497f0642cfa8755dd/6af66/uicolor-light-surface.png 640w", "/static/4d57e5c80481862497f0642cfa8755dd/d9199/uicolor-light-surface.png 960w", "/static/4d57e5c80481862497f0642cfa8755dd/21b4d/uicolor-light-surface.png 1280w", "/static/4d57e5c80481862497f0642cfa8755dd/aac3c/uicolor-light-surface.png 2897w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/4d57e5c80481862497f0642cfa8755dd/6af66/uicolor-light-surface.png",
                "alt": "Surface ColorのLightテーマのカラーパレット",
                "title": "Surface ColorのLightテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8a80605b90b19412a649950be81e1bbf/ed358/uicolor-dark-surface.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "152.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/8a80605b90b19412a649950be81e1bbf/2aaaf/uicolor-dark-surface.webp 160w", "/static/8a80605b90b19412a649950be81e1bbf/85e47/uicolor-dark-surface.webp 320w", "/static/8a80605b90b19412a649950be81e1bbf/75198/uicolor-dark-surface.webp 640w", "/static/8a80605b90b19412a649950be81e1bbf/691bc/uicolor-dark-surface.webp 960w", "/static/8a80605b90b19412a649950be81e1bbf/223e5/uicolor-dark-surface.webp 1280w", "/static/8a80605b90b19412a649950be81e1bbf/b1afa/uicolor-dark-surface.webp 2904w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/8a80605b90b19412a649950be81e1bbf/69538/uicolor-dark-surface.png 160w", "/static/8a80605b90b19412a649950be81e1bbf/72799/uicolor-dark-surface.png 320w", "/static/8a80605b90b19412a649950be81e1bbf/6af66/uicolor-dark-surface.png 640w", "/static/8a80605b90b19412a649950be81e1bbf/d9199/uicolor-dark-surface.png 960w", "/static/8a80605b90b19412a649950be81e1bbf/21b4d/uicolor-dark-surface.png 1280w", "/static/8a80605b90b19412a649950be81e1bbf/ed358/uicolor-dark-surface.png 2904w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/8a80605b90b19412a649950be81e1bbf/6af66/uicolor-dark-surface.png",
                "alt": "Surface ColorのDarkテーマのカラーパレット",
                "title": "Surface ColorのDarkテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    </div>
    <h3 {...{
      "id": "object-color",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#object-color",
        "aria-label": "object color permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Object Color`}</h3>
    <p>{`WCAG2.1に準拠し、原則として背景色に対してコントラスト比`}<inlineCode parentName="p">{`3:1`}</inlineCode>{`を保持する必要があります。
そのため、背景色に応じて選択可能な色が限定しているので注意してください。`}</p>
    <p>{`例外: Button等の非活性のUIの場合は `}<inlineCode parentName="p">{`3:1`}</inlineCode>{`未満の比率でも問題はありません。`}</p>
    <p>{`Expressive Pinkを使用する場合は、下記のルールに従ってください。`}</p>
    <ul>
      <li parentName="ul">{`緑かグレーまたは赤で実現可能な場合は使用しないこと`}</li>
      <li parentName="ul">{`1つのViewに対して使っていい色は3色まで(仮)`}</li>
      <li parentName="ul">{`複数色使用する場合は、必ず同一の要素の中でのパターン分けの目的で使用すること`}</li>
    </ul>
    <div className="Stack">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/142a8e4fe3ce61823358a5d6cc1fff86/aac3c/uicolor-light-object.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/142a8e4fe3ce61823358a5d6cc1fff86/2aaaf/uicolor-light-object.webp 160w", "/static/142a8e4fe3ce61823358a5d6cc1fff86/85e47/uicolor-light-object.webp 320w", "/static/142a8e4fe3ce61823358a5d6cc1fff86/75198/uicolor-light-object.webp 640w", "/static/142a8e4fe3ce61823358a5d6cc1fff86/691bc/uicolor-light-object.webp 960w", "/static/142a8e4fe3ce61823358a5d6cc1fff86/223e5/uicolor-light-object.webp 1280w", "/static/142a8e4fe3ce61823358a5d6cc1fff86/84d70/uicolor-light-object.webp 2897w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/142a8e4fe3ce61823358a5d6cc1fff86/69538/uicolor-light-object.png 160w", "/static/142a8e4fe3ce61823358a5d6cc1fff86/72799/uicolor-light-object.png 320w", "/static/142a8e4fe3ce61823358a5d6cc1fff86/6af66/uicolor-light-object.png 640w", "/static/142a8e4fe3ce61823358a5d6cc1fff86/d9199/uicolor-light-object.png 960w", "/static/142a8e4fe3ce61823358a5d6cc1fff86/21b4d/uicolor-light-object.png 1280w", "/static/142a8e4fe3ce61823358a5d6cc1fff86/aac3c/uicolor-light-object.png 2897w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/142a8e4fe3ce61823358a5d6cc1fff86/6af66/uicolor-light-object.png",
                "alt": "Object ColorのLightテーマのカラーパレット",
                "title": "Object ColorのLightテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c83f2b869937d4f500fd5af1fb3e981c/ed358/uicolor-dark-object.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c83f2b869937d4f500fd5af1fb3e981c/2aaaf/uicolor-dark-object.webp 160w", "/static/c83f2b869937d4f500fd5af1fb3e981c/85e47/uicolor-dark-object.webp 320w", "/static/c83f2b869937d4f500fd5af1fb3e981c/75198/uicolor-dark-object.webp 640w", "/static/c83f2b869937d4f500fd5af1fb3e981c/691bc/uicolor-dark-object.webp 960w", "/static/c83f2b869937d4f500fd5af1fb3e981c/223e5/uicolor-dark-object.webp 1280w", "/static/c83f2b869937d4f500fd5af1fb3e981c/b1afa/uicolor-dark-object.webp 2904w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c83f2b869937d4f500fd5af1fb3e981c/69538/uicolor-dark-object.png 160w", "/static/c83f2b869937d4f500fd5af1fb3e981c/72799/uicolor-dark-object.png 320w", "/static/c83f2b869937d4f500fd5af1fb3e981c/6af66/uicolor-dark-object.png 640w", "/static/c83f2b869937d4f500fd5af1fb3e981c/d9199/uicolor-dark-object.png 960w", "/static/c83f2b869937d4f500fd5af1fb3e981c/21b4d/uicolor-dark-object.png 1280w", "/static/c83f2b869937d4f500fd5af1fb3e981c/ed358/uicolor-dark-object.png 2904w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/c83f2b869937d4f500fd5af1fb3e981c/6af66/uicolor-dark-object.png",
                "alt": "Object ColorのDarkテーマのカラーパレット",
                "title": "Object ColorのDarkテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    </div>
    <h3 {...{
      "id": "border-color",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#border-color",
        "aria-label": "border color permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Border Color`}</h3>
    <div className="Stack">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/aac3c/uicolor-light-border.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "36.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/2aaaf/uicolor-light-border.webp 160w", "/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/85e47/uicolor-light-border.webp 320w", "/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/75198/uicolor-light-border.webp 640w", "/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/691bc/uicolor-light-border.webp 960w", "/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/223e5/uicolor-light-border.webp 1280w", "/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/84d70/uicolor-light-border.webp 2897w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/69538/uicolor-light-border.png 160w", "/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/72799/uicolor-light-border.png 320w", "/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/6af66/uicolor-light-border.png 640w", "/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/d9199/uicolor-light-border.png 960w", "/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/21b4d/uicolor-light-border.png 1280w", "/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/aac3c/uicolor-light-border.png 2897w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/4d0e0cf4476e76f8ea9ecefd22dca5bf/6af66/uicolor-light-border.png",
                "alt": "Border ColorのLightテーマのカラーパレット",
                "title": "Border ColorのLightテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/1478dac8cccbfc6572da331882ffe49b/ed358/uicolor-dark-border.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "36.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1478dac8cccbfc6572da331882ffe49b/2aaaf/uicolor-dark-border.webp 160w", "/static/1478dac8cccbfc6572da331882ffe49b/85e47/uicolor-dark-border.webp 320w", "/static/1478dac8cccbfc6572da331882ffe49b/75198/uicolor-dark-border.webp 640w", "/static/1478dac8cccbfc6572da331882ffe49b/691bc/uicolor-dark-border.webp 960w", "/static/1478dac8cccbfc6572da331882ffe49b/223e5/uicolor-dark-border.webp 1280w", "/static/1478dac8cccbfc6572da331882ffe49b/b1afa/uicolor-dark-border.webp 2904w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1478dac8cccbfc6572da331882ffe49b/69538/uicolor-dark-border.png 160w", "/static/1478dac8cccbfc6572da331882ffe49b/72799/uicolor-dark-border.png 320w", "/static/1478dac8cccbfc6572da331882ffe49b/6af66/uicolor-dark-border.png 640w", "/static/1478dac8cccbfc6572da331882ffe49b/d9199/uicolor-dark-border.png 960w", "/static/1478dac8cccbfc6572da331882ffe49b/21b4d/uicolor-dark-border.png 1280w", "/static/1478dac8cccbfc6572da331882ffe49b/ed358/uicolor-dark-border.png 2904w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/1478dac8cccbfc6572da331882ffe49b/6af66/uicolor-dark-border.png",
                "alt": "Border ColorのDarkテーマのカラーパレット",
                "title": "Border ColorのDarkテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    </div>
    <h3 {...{
      "id": "highlight-color",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#highlight-color",
        "aria-label": "highlight color permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Highlight Color`}</h3>
    <p>{`テキストの選択範囲や、文字数オーバーのエラーを指し示すときなどに使用します。
カラーはテキストの上に乗せます。`}</p>
    <p>{`例外: Highlight Yellowの場合はTextの背面に配置します。その場合、マーカーの下線のように、あるいは文字の背景色として使用します。`}</p>
    <div className="Stack">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/9d79b8f93f11863057009ef559719cea/864a6/uicolor-light-highlight.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/9d79b8f93f11863057009ef559719cea/2aaaf/uicolor-light-highlight.webp 160w", "/static/9d79b8f93f11863057009ef559719cea/85e47/uicolor-light-highlight.webp 320w", "/static/9d79b8f93f11863057009ef559719cea/75198/uicolor-light-highlight.webp 640w", "/static/9d79b8f93f11863057009ef559719cea/691bc/uicolor-light-highlight.webp 960w", "/static/9d79b8f93f11863057009ef559719cea/223e5/uicolor-light-highlight.webp 1280w", "/static/9d79b8f93f11863057009ef559719cea/74e35/uicolor-light-highlight.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/9d79b8f93f11863057009ef559719cea/69538/uicolor-light-highlight.png 160w", "/static/9d79b8f93f11863057009ef559719cea/72799/uicolor-light-highlight.png 320w", "/static/9d79b8f93f11863057009ef559719cea/6af66/uicolor-light-highlight.png 640w", "/static/9d79b8f93f11863057009ef559719cea/d9199/uicolor-light-highlight.png 960w", "/static/9d79b8f93f11863057009ef559719cea/21b4d/uicolor-light-highlight.png 1280w", "/static/9d79b8f93f11863057009ef559719cea/864a6/uicolor-light-highlight.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/9d79b8f93f11863057009ef559719cea/6af66/uicolor-light-highlight.png",
                "alt": "Highlight ColorのLightテーマのカラーパレット",
                "title": "Highlight ColorのLightテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/52617d055a6d3b3813a5855d62ae3c5d/864a6/uicolor-dark-highlight.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/52617d055a6d3b3813a5855d62ae3c5d/2aaaf/uicolor-dark-highlight.webp 160w", "/static/52617d055a6d3b3813a5855d62ae3c5d/85e47/uicolor-dark-highlight.webp 320w", "/static/52617d055a6d3b3813a5855d62ae3c5d/75198/uicolor-dark-highlight.webp 640w", "/static/52617d055a6d3b3813a5855d62ae3c5d/691bc/uicolor-dark-highlight.webp 960w", "/static/52617d055a6d3b3813a5855d62ae3c5d/223e5/uicolor-dark-highlight.webp 1280w", "/static/52617d055a6d3b3813a5855d62ae3c5d/74e35/uicolor-dark-highlight.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/52617d055a6d3b3813a5855d62ae3c5d/69538/uicolor-dark-highlight.png 160w", "/static/52617d055a6d3b3813a5855d62ae3c5d/72799/uicolor-dark-highlight.png 320w", "/static/52617d055a6d3b3813a5855d62ae3c5d/6af66/uicolor-dark-highlight.png 640w", "/static/52617d055a6d3b3813a5855d62ae3c5d/d9199/uicolor-dark-highlight.png 960w", "/static/52617d055a6d3b3813a5855d62ae3c5d/21b4d/uicolor-dark-highlight.png 1280w", "/static/52617d055a6d3b3813a5855d62ae3c5d/864a6/uicolor-dark-highlight.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/52617d055a6d3b3813a5855d62ae3c5d/6af66/uicolor-dark-highlight.png",
                "alt": "Highlight ColorのDarkテーマのカラーパレット",
                "title": "Highlight ColorのDarkテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    </div>
    <h3 {...{
      "id": "focus-color",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#focus-color",
        "aria-label": "focus color permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Focus Color`}</h3>
    <p>{`ユーザーが要素をクリックまたがタップをしたり、キーボードのTabキー操作で選択したりしたときに出現するFocusのスタイルに使用するカラーです。`}</p>
    <div className="Stack">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6ccc65c4504b1253065ffdf1da55ef28/864a6/uicolor-light-focus.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/6ccc65c4504b1253065ffdf1da55ef28/2aaaf/uicolor-light-focus.webp 160w", "/static/6ccc65c4504b1253065ffdf1da55ef28/85e47/uicolor-light-focus.webp 320w", "/static/6ccc65c4504b1253065ffdf1da55ef28/75198/uicolor-light-focus.webp 640w", "/static/6ccc65c4504b1253065ffdf1da55ef28/691bc/uicolor-light-focus.webp 960w", "/static/6ccc65c4504b1253065ffdf1da55ef28/223e5/uicolor-light-focus.webp 1280w", "/static/6ccc65c4504b1253065ffdf1da55ef28/74e35/uicolor-light-focus.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/6ccc65c4504b1253065ffdf1da55ef28/69538/uicolor-light-focus.png 160w", "/static/6ccc65c4504b1253065ffdf1da55ef28/72799/uicolor-light-focus.png 320w", "/static/6ccc65c4504b1253065ffdf1da55ef28/6af66/uicolor-light-focus.png 640w", "/static/6ccc65c4504b1253065ffdf1da55ef28/d9199/uicolor-light-focus.png 960w", "/static/6ccc65c4504b1253065ffdf1da55ef28/21b4d/uicolor-light-focus.png 1280w", "/static/6ccc65c4504b1253065ffdf1da55ef28/864a6/uicolor-light-focus.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/6ccc65c4504b1253065ffdf1da55ef28/6af66/uicolor-light-focus.png",
                "alt": "Focus ColorのLightテーマのカラーパレット",
                "title": "Focus ColorのLightテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/bf22beaa15e03fba2bd59a8deed6d80d/864a6/uicolor-dark-focus.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/bf22beaa15e03fba2bd59a8deed6d80d/2aaaf/uicolor-dark-focus.webp 160w", "/static/bf22beaa15e03fba2bd59a8deed6d80d/85e47/uicolor-dark-focus.webp 320w", "/static/bf22beaa15e03fba2bd59a8deed6d80d/75198/uicolor-dark-focus.webp 640w", "/static/bf22beaa15e03fba2bd59a8deed6d80d/691bc/uicolor-dark-focus.webp 960w", "/static/bf22beaa15e03fba2bd59a8deed6d80d/223e5/uicolor-dark-focus.webp 1280w", "/static/bf22beaa15e03fba2bd59a8deed6d80d/74e35/uicolor-dark-focus.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/bf22beaa15e03fba2bd59a8deed6d80d/69538/uicolor-dark-focus.png 160w", "/static/bf22beaa15e03fba2bd59a8deed6d80d/72799/uicolor-dark-focus.png 320w", "/static/bf22beaa15e03fba2bd59a8deed6d80d/6af66/uicolor-dark-focus.png 640w", "/static/bf22beaa15e03fba2bd59a8deed6d80d/d9199/uicolor-dark-focus.png 960w", "/static/bf22beaa15e03fba2bd59a8deed6d80d/21b4d/uicolor-dark-focus.png 1280w", "/static/bf22beaa15e03fba2bd59a8deed6d80d/864a6/uicolor-dark-focus.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/bf22beaa15e03fba2bd59a8deed6d80d/6af66/uicolor-dark-focus.png",
                "alt": "Focus ColorのDarkテーマのカラーパレット",
                "title": "Focus ColorのDarkテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    </div>
    <h3 {...{
      "id": "third-party-color",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#third-party-color",
        "aria-label": "third party color permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Third Party Color`}</h3>
    <p>{`他社サービスの機能や表現を組み込む時に、それぞれのガイドラインに則って使用されるカラーです。
外部サービスに依存するため、必ずしもコントラスト比を担保できるものではないことに注意してください。`}</p>
    <div className="Stack">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/51e684ddced9ba327d153f49f6a83a55/864a6/uicolor-light-third-party.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/51e684ddced9ba327d153f49f6a83a55/2aaaf/uicolor-light-third-party.webp 160w", "/static/51e684ddced9ba327d153f49f6a83a55/85e47/uicolor-light-third-party.webp 320w", "/static/51e684ddced9ba327d153f49f6a83a55/75198/uicolor-light-third-party.webp 640w", "/static/51e684ddced9ba327d153f49f6a83a55/691bc/uicolor-light-third-party.webp 960w", "/static/51e684ddced9ba327d153f49f6a83a55/223e5/uicolor-light-third-party.webp 1280w", "/static/51e684ddced9ba327d153f49f6a83a55/74e35/uicolor-light-third-party.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/51e684ddced9ba327d153f49f6a83a55/69538/uicolor-light-third-party.png 160w", "/static/51e684ddced9ba327d153f49f6a83a55/72799/uicolor-light-third-party.png 320w", "/static/51e684ddced9ba327d153f49f6a83a55/6af66/uicolor-light-third-party.png 640w", "/static/51e684ddced9ba327d153f49f6a83a55/d9199/uicolor-light-third-party.png 960w", "/static/51e684ddced9ba327d153f49f6a83a55/21b4d/uicolor-light-third-party.png 1280w", "/static/51e684ddced9ba327d153f49f6a83a55/864a6/uicolor-light-third-party.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/51e684ddced9ba327d153f49f6a83a55/6af66/uicolor-light-third-party.png",
                "alt": "Third Party ColorのLightテーマのカラーパレット",
                "title": "Third Party ColorのLightテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3216e007eabf2d8b63045e9a99eddb1a/864a6/uicolor-dark-third-party.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/3216e007eabf2d8b63045e9a99eddb1a/2aaaf/uicolor-dark-third-party.webp 160w", "/static/3216e007eabf2d8b63045e9a99eddb1a/85e47/uicolor-dark-third-party.webp 320w", "/static/3216e007eabf2d8b63045e9a99eddb1a/75198/uicolor-dark-third-party.webp 640w", "/static/3216e007eabf2d8b63045e9a99eddb1a/691bc/uicolor-dark-third-party.webp 960w", "/static/3216e007eabf2d8b63045e9a99eddb1a/223e5/uicolor-dark-third-party.webp 1280w", "/static/3216e007eabf2d8b63045e9a99eddb1a/74e35/uicolor-dark-third-party.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/3216e007eabf2d8b63045e9a99eddb1a/69538/uicolor-dark-third-party.png 160w", "/static/3216e007eabf2d8b63045e9a99eddb1a/72799/uicolor-dark-third-party.png 320w", "/static/3216e007eabf2d8b63045e9a99eddb1a/6af66/uicolor-dark-third-party.png 640w", "/static/3216e007eabf2d8b63045e9a99eddb1a/d9199/uicolor-dark-third-party.png 960w", "/static/3216e007eabf2d8b63045e9a99eddb1a/21b4d/uicolor-dark-third-party.png 1280w", "/static/3216e007eabf2d8b63045e9a99eddb1a/864a6/uicolor-dark-third-party.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/3216e007eabf2d8b63045e9a99eddb1a/6af66/uicolor-dark-third-party.png",
                "alt": "Third Party ColorのDarkテーマのカラーパレット",
                "title": "Third Party ColorのDarkテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    </div>
    <h3 {...{
      "id": "brand-color",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#brand-color",
        "aria-label": "brand color permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Brand Color`}</h3>
    <p>{`Amebaロゴなど、ブランドとして最重要な部分のみ使用する特色的なカラーです。`}</p>
    <div className="Stack">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e6965222a14c01d7058dece90401f697/864a6/uicolor-light-brand.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/e6965222a14c01d7058dece90401f697/2aaaf/uicolor-light-brand.webp 160w", "/static/e6965222a14c01d7058dece90401f697/85e47/uicolor-light-brand.webp 320w", "/static/e6965222a14c01d7058dece90401f697/75198/uicolor-light-brand.webp 640w", "/static/e6965222a14c01d7058dece90401f697/691bc/uicolor-light-brand.webp 960w", "/static/e6965222a14c01d7058dece90401f697/223e5/uicolor-light-brand.webp 1280w", "/static/e6965222a14c01d7058dece90401f697/74e35/uicolor-light-brand.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/e6965222a14c01d7058dece90401f697/69538/uicolor-light-brand.png 160w", "/static/e6965222a14c01d7058dece90401f697/72799/uicolor-light-brand.png 320w", "/static/e6965222a14c01d7058dece90401f697/6af66/uicolor-light-brand.png 640w", "/static/e6965222a14c01d7058dece90401f697/d9199/uicolor-light-brand.png 960w", "/static/e6965222a14c01d7058dece90401f697/21b4d/uicolor-light-brand.png 1280w", "/static/e6965222a14c01d7058dece90401f697/864a6/uicolor-light-brand.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/e6965222a14c01d7058dece90401f697/6af66/uicolor-light-brand.png",
                "alt": "Brand ColorのLightテーマのカラーパレット",
                "title": "Brand ColorのLightテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/78a4f07a70c67206405e90b9de67c30a/864a6/uicolor-dark-brand.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/78a4f07a70c67206405e90b9de67c30a/2aaaf/uicolor-dark-brand.webp 160w", "/static/78a4f07a70c67206405e90b9de67c30a/85e47/uicolor-dark-brand.webp 320w", "/static/78a4f07a70c67206405e90b9de67c30a/75198/uicolor-dark-brand.webp 640w", "/static/78a4f07a70c67206405e90b9de67c30a/691bc/uicolor-dark-brand.webp 960w", "/static/78a4f07a70c67206405e90b9de67c30a/223e5/uicolor-dark-brand.webp 1280w", "/static/78a4f07a70c67206405e90b9de67c30a/74e35/uicolor-dark-brand.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/78a4f07a70c67206405e90b9de67c30a/69538/uicolor-dark-brand.png 160w", "/static/78a4f07a70c67206405e90b9de67c30a/72799/uicolor-dark-brand.png 320w", "/static/78a4f07a70c67206405e90b9de67c30a/6af66/uicolor-dark-brand.png 640w", "/static/78a4f07a70c67206405e90b9de67c30a/d9199/uicolor-dark-brand.png 960w", "/static/78a4f07a70c67206405e90b9de67c30a/21b4d/uicolor-dark-brand.png 1280w", "/static/78a4f07a70c67206405e90b9de67c30a/864a6/uicolor-dark-brand.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/78a4f07a70c67206405e90b9de67c30a/6af66/uicolor-dark-brand.png",
                "alt": "Brand ColorのDarkテーマのカラーパレット",
                "title": "Brand ColorのDarkテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    </div>
    <h3 {...{
      "id": "その他",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96",
        "aria-label": "その他 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`その他`}</h3>
    <p>{`プラットフォーム別のインタラクションのためのカラーがあります。`}</p>
    <div className="Stack">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/917ba4ee8c14ecae8b08f48b62b229c6/864a6/uicolor-light-tap-highlight.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/917ba4ee8c14ecae8b08f48b62b229c6/2aaaf/uicolor-light-tap-highlight.webp 160w", "/static/917ba4ee8c14ecae8b08f48b62b229c6/85e47/uicolor-light-tap-highlight.webp 320w", "/static/917ba4ee8c14ecae8b08f48b62b229c6/75198/uicolor-light-tap-highlight.webp 640w", "/static/917ba4ee8c14ecae8b08f48b62b229c6/691bc/uicolor-light-tap-highlight.webp 960w", "/static/917ba4ee8c14ecae8b08f48b62b229c6/223e5/uicolor-light-tap-highlight.webp 1280w", "/static/917ba4ee8c14ecae8b08f48b62b229c6/74e35/uicolor-light-tap-highlight.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/917ba4ee8c14ecae8b08f48b62b229c6/69538/uicolor-light-tap-highlight.png 160w", "/static/917ba4ee8c14ecae8b08f48b62b229c6/72799/uicolor-light-tap-highlight.png 320w", "/static/917ba4ee8c14ecae8b08f48b62b229c6/6af66/uicolor-light-tap-highlight.png 640w", "/static/917ba4ee8c14ecae8b08f48b62b229c6/d9199/uicolor-light-tap-highlight.png 960w", "/static/917ba4ee8c14ecae8b08f48b62b229c6/21b4d/uicolor-light-tap-highlight.png 1280w", "/static/917ba4ee8c14ecae8b08f48b62b229c6/864a6/uicolor-light-tap-highlight.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/917ba4ee8c14ecae8b08f48b62b229c6/6af66/uicolor-light-tap-highlight.png",
                "alt": "Web向けのTap HighlightのLightテーマのカラーパレット",
                "title": "Web向けのTap HighlightのLightテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/a3c95513ed5a00cb760fd4d21c524995/864a6/uicolor-dark-tap-highlight.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/a3c95513ed5a00cb760fd4d21c524995/2aaaf/uicolor-dark-tap-highlight.webp 160w", "/static/a3c95513ed5a00cb760fd4d21c524995/85e47/uicolor-dark-tap-highlight.webp 320w", "/static/a3c95513ed5a00cb760fd4d21c524995/75198/uicolor-dark-tap-highlight.webp 640w", "/static/a3c95513ed5a00cb760fd4d21c524995/691bc/uicolor-dark-tap-highlight.webp 960w", "/static/a3c95513ed5a00cb760fd4d21c524995/223e5/uicolor-dark-tap-highlight.webp 1280w", "/static/a3c95513ed5a00cb760fd4d21c524995/74e35/uicolor-dark-tap-highlight.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/a3c95513ed5a00cb760fd4d21c524995/69538/uicolor-dark-tap-highlight.png 160w", "/static/a3c95513ed5a00cb760fd4d21c524995/72799/uicolor-dark-tap-highlight.png 320w", "/static/a3c95513ed5a00cb760fd4d21c524995/6af66/uicolor-dark-tap-highlight.png 640w", "/static/a3c95513ed5a00cb760fd4d21c524995/d9199/uicolor-dark-tap-highlight.png 960w", "/static/a3c95513ed5a00cb760fd4d21c524995/21b4d/uicolor-dark-tap-highlight.png 1280w", "/static/a3c95513ed5a00cb760fd4d21c524995/864a6/uicolor-dark-tap-highlight.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/a3c95513ed5a00cb760fd4d21c524995/6af66/uicolor-dark-tap-highlight.png",
                "alt": "Web向けのTap HighlightのDarkテーマのカラーパレット",
                "title": "Web向けのTap HighlightのDarkテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    </div>
    <div className="Stack">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0ef543b473ba26e3afb40447adcd0a19/864a6/uicolor-light-hover.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0ef543b473ba26e3afb40447adcd0a19/2aaaf/uicolor-light-hover.webp 160w", "/static/0ef543b473ba26e3afb40447adcd0a19/85e47/uicolor-light-hover.webp 320w", "/static/0ef543b473ba26e3afb40447adcd0a19/75198/uicolor-light-hover.webp 640w", "/static/0ef543b473ba26e3afb40447adcd0a19/691bc/uicolor-light-hover.webp 960w", "/static/0ef543b473ba26e3afb40447adcd0a19/223e5/uicolor-light-hover.webp 1280w", "/static/0ef543b473ba26e3afb40447adcd0a19/74e35/uicolor-light-hover.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0ef543b473ba26e3afb40447adcd0a19/69538/uicolor-light-hover.png 160w", "/static/0ef543b473ba26e3afb40447adcd0a19/72799/uicolor-light-hover.png 320w", "/static/0ef543b473ba26e3afb40447adcd0a19/6af66/uicolor-light-hover.png 640w", "/static/0ef543b473ba26e3afb40447adcd0a19/d9199/uicolor-light-hover.png 960w", "/static/0ef543b473ba26e3afb40447adcd0a19/21b4d/uicolor-light-hover.png 1280w", "/static/0ef543b473ba26e3afb40447adcd0a19/864a6/uicolor-light-hover.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/0ef543b473ba26e3afb40447adcd0a19/6af66/uicolor-light-hover.png",
                "alt": "Web向けのHoverのLightテーマのカラーパレット",
                "title": "Web向けのHoverのLightテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/a682e9381dcdc12675282d5601094917/864a6/uicolor-dark-hover.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/a682e9381dcdc12675282d5601094917/2aaaf/uicolor-dark-hover.webp 160w", "/static/a682e9381dcdc12675282d5601094917/85e47/uicolor-dark-hover.webp 320w", "/static/a682e9381dcdc12675282d5601094917/75198/uicolor-dark-hover.webp 640w", "/static/a682e9381dcdc12675282d5601094917/691bc/uicolor-dark-hover.webp 960w", "/static/a682e9381dcdc12675282d5601094917/223e5/uicolor-dark-hover.webp 1280w", "/static/a682e9381dcdc12675282d5601094917/74e35/uicolor-dark-hover.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/a682e9381dcdc12675282d5601094917/69538/uicolor-dark-hover.png 160w", "/static/a682e9381dcdc12675282d5601094917/72799/uicolor-dark-hover.png 320w", "/static/a682e9381dcdc12675282d5601094917/6af66/uicolor-dark-hover.png 640w", "/static/a682e9381dcdc12675282d5601094917/d9199/uicolor-dark-hover.png 960w", "/static/a682e9381dcdc12675282d5601094917/21b4d/uicolor-dark-hover.png 1280w", "/static/a682e9381dcdc12675282d5601094917/864a6/uicolor-dark-hover.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/a682e9381dcdc12675282d5601094917/6af66/uicolor-dark-hover.png",
                "alt": "Web向けのHoverのDarkテーマのカラーパレット",
                "title": "Web向けのHoverのDarkテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    </div>
    <div className="Stack">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/98e5c8551d39b11ef4909951d8b2aa1c/864a6/uicolor-light-active.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "47.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/98e5c8551d39b11ef4909951d8b2aa1c/2aaaf/uicolor-light-active.webp 160w", "/static/98e5c8551d39b11ef4909951d8b2aa1c/85e47/uicolor-light-active.webp 320w", "/static/98e5c8551d39b11ef4909951d8b2aa1c/75198/uicolor-light-active.webp 640w", "/static/98e5c8551d39b11ef4909951d8b2aa1c/691bc/uicolor-light-active.webp 960w", "/static/98e5c8551d39b11ef4909951d8b2aa1c/223e5/uicolor-light-active.webp 1280w", "/static/98e5c8551d39b11ef4909951d8b2aa1c/74e35/uicolor-light-active.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/98e5c8551d39b11ef4909951d8b2aa1c/69538/uicolor-light-active.png 160w", "/static/98e5c8551d39b11ef4909951d8b2aa1c/72799/uicolor-light-active.png 320w", "/static/98e5c8551d39b11ef4909951d8b2aa1c/6af66/uicolor-light-active.png 640w", "/static/98e5c8551d39b11ef4909951d8b2aa1c/d9199/uicolor-light-active.png 960w", "/static/98e5c8551d39b11ef4909951d8b2aa1c/21b4d/uicolor-light-active.png 1280w", "/static/98e5c8551d39b11ef4909951d8b2aa1c/864a6/uicolor-light-active.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/98e5c8551d39b11ef4909951d8b2aa1c/6af66/uicolor-light-active.png",
                "alt": "Web向けのActiveのLightテーマのカラーパレット",
                "title": "Web向けのActiveのLightテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/5b63842de97bc1a406eb64881d76c83f/864a6/uicolor-dark-active.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/5b63842de97bc1a406eb64881d76c83f/2aaaf/uicolor-dark-active.webp 160w", "/static/5b63842de97bc1a406eb64881d76c83f/85e47/uicolor-dark-active.webp 320w", "/static/5b63842de97bc1a406eb64881d76c83f/75198/uicolor-dark-active.webp 640w", "/static/5b63842de97bc1a406eb64881d76c83f/691bc/uicolor-dark-active.webp 960w", "/static/5b63842de97bc1a406eb64881d76c83f/223e5/uicolor-dark-active.webp 1280w", "/static/5b63842de97bc1a406eb64881d76c83f/74e35/uicolor-dark-active.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/5b63842de97bc1a406eb64881d76c83f/69538/uicolor-dark-active.png 160w", "/static/5b63842de97bc1a406eb64881d76c83f/72799/uicolor-dark-active.png 320w", "/static/5b63842de97bc1a406eb64881d76c83f/6af66/uicolor-dark-active.png 640w", "/static/5b63842de97bc1a406eb64881d76c83f/d9199/uicolor-dark-active.png 960w", "/static/5b63842de97bc1a406eb64881d76c83f/21b4d/uicolor-dark-active.png 1280w", "/static/5b63842de97bc1a406eb64881d76c83f/864a6/uicolor-dark-active.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/5b63842de97bc1a406eb64881d76c83f/6af66/uicolor-dark-active.png",
                "alt": "Web向けのActiveのDarkテーマのカラーパレット",
                "title": "Web向けのActiveのDarkテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    </div>
    <div className="Stack">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/5a0ac64d3fd3de8202a8517e2845e471/864a6/uicolor-light-highlighted.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/5a0ac64d3fd3de8202a8517e2845e471/2aaaf/uicolor-light-highlighted.webp 160w", "/static/5a0ac64d3fd3de8202a8517e2845e471/85e47/uicolor-light-highlighted.webp 320w", "/static/5a0ac64d3fd3de8202a8517e2845e471/75198/uicolor-light-highlighted.webp 640w", "/static/5a0ac64d3fd3de8202a8517e2845e471/691bc/uicolor-light-highlighted.webp 960w", "/static/5a0ac64d3fd3de8202a8517e2845e471/223e5/uicolor-light-highlighted.webp 1280w", "/static/5a0ac64d3fd3de8202a8517e2845e471/74e35/uicolor-light-highlighted.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/5a0ac64d3fd3de8202a8517e2845e471/69538/uicolor-light-highlighted.png 160w", "/static/5a0ac64d3fd3de8202a8517e2845e471/72799/uicolor-light-highlighted.png 320w", "/static/5a0ac64d3fd3de8202a8517e2845e471/6af66/uicolor-light-highlighted.png 640w", "/static/5a0ac64d3fd3de8202a8517e2845e471/d9199/uicolor-light-highlighted.png 960w", "/static/5a0ac64d3fd3de8202a8517e2845e471/21b4d/uicolor-light-highlighted.png 1280w", "/static/5a0ac64d3fd3de8202a8517e2845e471/864a6/uicolor-light-highlighted.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/5a0ac64d3fd3de8202a8517e2845e471/6af66/uicolor-light-highlighted.png",
                "alt": "iOS向けのHighlightedのLightテーマのカラーパレット",
                "title": "iOS向けのHighlightedのLightテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d5c3b064cbc112cffca085ea84eaeeea/864a6/uicolor-dark-highlighted.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d5c3b064cbc112cffca085ea84eaeeea/2aaaf/uicolor-dark-highlighted.webp 160w", "/static/d5c3b064cbc112cffca085ea84eaeeea/85e47/uicolor-dark-highlighted.webp 320w", "/static/d5c3b064cbc112cffca085ea84eaeeea/75198/uicolor-dark-highlighted.webp 640w", "/static/d5c3b064cbc112cffca085ea84eaeeea/691bc/uicolor-dark-highlighted.webp 960w", "/static/d5c3b064cbc112cffca085ea84eaeeea/223e5/uicolor-dark-highlighted.webp 1280w", "/static/d5c3b064cbc112cffca085ea84eaeeea/74e35/uicolor-dark-highlighted.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d5c3b064cbc112cffca085ea84eaeeea/69538/uicolor-dark-highlighted.png 160w", "/static/d5c3b064cbc112cffca085ea84eaeeea/72799/uicolor-dark-highlighted.png 320w", "/static/d5c3b064cbc112cffca085ea84eaeeea/6af66/uicolor-dark-highlighted.png 640w", "/static/d5c3b064cbc112cffca085ea84eaeeea/d9199/uicolor-dark-highlighted.png 960w", "/static/d5c3b064cbc112cffca085ea84eaeeea/21b4d/uicolor-dark-highlighted.png 1280w", "/static/d5c3b064cbc112cffca085ea84eaeeea/864a6/uicolor-dark-highlighted.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/d5c3b064cbc112cffca085ea84eaeeea/6af66/uicolor-dark-highlighted.png",
                "alt": "iOS向けのHighlightedのDarkテーマのカラーパレット",
                "title": "iOS向けのHighlightedのDarkテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    </div>
    <div className="Stack">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/bffa703135dcd20751f9466ea37f3a30/864a6/uicolor-light-ripple.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/bffa703135dcd20751f9466ea37f3a30/2aaaf/uicolor-light-ripple.webp 160w", "/static/bffa703135dcd20751f9466ea37f3a30/85e47/uicolor-light-ripple.webp 320w", "/static/bffa703135dcd20751f9466ea37f3a30/75198/uicolor-light-ripple.webp 640w", "/static/bffa703135dcd20751f9466ea37f3a30/691bc/uicolor-light-ripple.webp 960w", "/static/bffa703135dcd20751f9466ea37f3a30/223e5/uicolor-light-ripple.webp 1280w", "/static/bffa703135dcd20751f9466ea37f3a30/74e35/uicolor-light-ripple.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/bffa703135dcd20751f9466ea37f3a30/69538/uicolor-light-ripple.png 160w", "/static/bffa703135dcd20751f9466ea37f3a30/72799/uicolor-light-ripple.png 320w", "/static/bffa703135dcd20751f9466ea37f3a30/6af66/uicolor-light-ripple.png 640w", "/static/bffa703135dcd20751f9466ea37f3a30/d9199/uicolor-light-ripple.png 960w", "/static/bffa703135dcd20751f9466ea37f3a30/21b4d/uicolor-light-ripple.png 1280w", "/static/bffa703135dcd20751f9466ea37f3a30/864a6/uicolor-light-ripple.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/bffa703135dcd20751f9466ea37f3a30/6af66/uicolor-light-ripple.png",
                "alt": "Android向けのRippleのLightテーマのカラーパレット",
                "title": "Android向けのRippleのLightテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c8e7cd327a01747f9e7d3b286a40e094/864a6/uicolor-dark-ripple.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c8e7cd327a01747f9e7d3b286a40e094/2aaaf/uicolor-dark-ripple.webp 160w", "/static/c8e7cd327a01747f9e7d3b286a40e094/85e47/uicolor-dark-ripple.webp 320w", "/static/c8e7cd327a01747f9e7d3b286a40e094/75198/uicolor-dark-ripple.webp 640w", "/static/c8e7cd327a01747f9e7d3b286a40e094/691bc/uicolor-dark-ripple.webp 960w", "/static/c8e7cd327a01747f9e7d3b286a40e094/223e5/uicolor-dark-ripple.webp 1280w", "/static/c8e7cd327a01747f9e7d3b286a40e094/74e35/uicolor-dark-ripple.webp 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c8e7cd327a01747f9e7d3b286a40e094/69538/uicolor-dark-ripple.png 160w", "/static/c8e7cd327a01747f9e7d3b286a40e094/72799/uicolor-dark-ripple.png 320w", "/static/c8e7cd327a01747f9e7d3b286a40e094/6af66/uicolor-dark-ripple.png 640w", "/static/c8e7cd327a01747f9e7d3b286a40e094/d9199/uicolor-dark-ripple.png 960w", "/static/c8e7cd327a01747f9e7d3b286a40e094/21b4d/uicolor-dark-ripple.png 1280w", "/static/c8e7cd327a01747f9e7d3b286a40e094/864a6/uicolor-dark-ripple.png 2276w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/c8e7cd327a01747f9e7d3b286a40e094/6af66/uicolor-dark-ripple.png",
                "alt": "Android向けのRippleのDarkテーマのカラーパレット",
                "title": "Android向けのRippleのDarkテーマのカラーパレット",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    </div>
    <h2 {...{
      "id": "用例",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%94%A8%E4%BE%8B",
        "aria-label": "用例 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`用例`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0522a0bed5b7ba8535a16915f1f85659/37523/uicolor-architecture.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0522a0bed5b7ba8535a16915f1f85659/2aaaf/uicolor-architecture.webp 160w", "/static/0522a0bed5b7ba8535a16915f1f85659/85e47/uicolor-architecture.webp 320w", "/static/0522a0bed5b7ba8535a16915f1f85659/75198/uicolor-architecture.webp 640w", "/static/0522a0bed5b7ba8535a16915f1f85659/e961d/uicolor-architecture.webp 720w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0522a0bed5b7ba8535a16915f1f85659/69538/uicolor-architecture.png 160w", "/static/0522a0bed5b7ba8535a16915f1f85659/72799/uicolor-architecture.png 320w", "/static/0522a0bed5b7ba8535a16915f1f85659/6af66/uicolor-architecture.png 640w", "/static/0522a0bed5b7ba8535a16915f1f85659/37523/uicolor-architecture.png 720w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0522a0bed5b7ba8535a16915f1f85659/6af66/uicolor-architecture.png",
              "alt": "よくあるモバイル向けのUIを元に、各カラーの配置例を説明した図",
              "title": "よくあるモバイル向けのUIを元に、各カラーの配置例を説明した図",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "手順",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%89%8B%E9%A0%86",
        "aria-label": "手順 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`手順`}</h3>
    <ol>
      <li parentName="ol">{`背景色をBackground Colorに設定する`}</li>
      <li parentName="ol">{`Surface Colorを使用して背景を作る`}</li>
      <li parentName="ol">{`要素を配置し、種類に応じてColorを決める`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Textを置く場合: SurfaceColorから、それに対応したTextColorを選ぶ`}</li>
      <li parentName="ul">{`Iconを置く場合: ObjectColorから任意の色を選ぶ`}</li>
      <li parentName="ul">{`ButtonやLabelなど、背景とTextがセットになったComponentの場合: SurfaceColorと、それに対応Text Colorを選択する`}</li>
      <li parentName="ul">{`Textにハイライトを付けようとしている場合: Textの上にHighlight Colorを被せる`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      